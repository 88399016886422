const get = require('lodash/get');
const APIService = require('../../services/api');
const { trackEvent, trackEventWithCustomField, trackEventWithCustomFields, trackPage } = require('../../lib/tracking');
const { processRequest, requestQueue } = require('../utils/addToCartRequestQueue');
const { feStatsdHelper } = require('../../utils/frontendStatsHelper');

const FEEDBACK_STYLE_ERROR = 'ERROR';
const ID_COMPATS_WIDGET = 'compats_widget';
const ID_COMPATS_WIDGET_MESSAGE = 'COMPATS_MESSAGE';
const SELLER = 'seller';
const COMPATS = 'compats';
const COMPATS_MESSAGE_TOP = 'COMPATS_MESSAGE_TOP';
const { OPERATION_FILL, OPERATION_REMOVE } = require('../../components/installation-service/installation.constants');

const {
  FETCH_ERROR,
  TOGGLE_BOOKMARKED,
  TOGGLE_BOOKMARK_OPTIMISTICALLY,
  FETCH_START,
  FETCH_PRODUCT_VARIATIONS_DETAILS,
  FETCH_COMPLETE,
  QUANTITY_SHOW_INPUT_CHANGE,
  FETCH_ON_SHIPPING_UPDATE_START,
  FETCH_ON_SET_QUANTITY_START,
  FETCH_ON_SET_QUANTITY_COMPLETE,
  FETCH_ON_SHIPPING_UPDATE_COMPLETE,
  SHOW_QUANTITY_ERROR,
  SHOW_VARIATIONS_ERROR_DESKTOP,
  SHOW_VARIATIONS_ERROR_MOBILE,
  FETCH_MORE_QUESTIONS_START,
  FETCH_MORE_QUESTIONS_COMPLETE_DESKTOP,
  FETCH_MORE_QUESTIONS_COMPLETE_MOBILE,
  CREATE_QUESTION_START,
  CREATE_QUESTION_COMPLETE,
  CREATE_QUESTION_FROM_AI_COMPLETE,
  CREATE_QUESTION_COMPLETE_VIEW,
  CREATE_QUESTION_ERROR,
  ADD_TO_CART_UPDATE_START,
  ADD_TO_CART_UPDATE_COMPLETE,
  ADD_TO_CART_UPDATE_ERROR,
  FETCH_COMPATIBLE_POSIBLE_VALUES_START,
  FETCH_COMPATIBLE_POSIBLE_VALUES_COMPLETE,
  FETCH_COMPATIBLE_POSIBLE_VALUES_ERROR,
  SET_COMPATIBILITIES_INPUT_VALUE,
  FETCH_COMPATIBILITIES_WITH_USER_SELECTION_START,
  FETCH_COMPATIBILITIES_WITH_USER_SELECTION_COMPLETE,
  FETCH_COMPATIBILITIES_WITH_USER_SELECTION_ERROR,
  FETCH_COMPATS_SEARCH_START,
  FETCH_COMPATS_SEARCH_COMPLETE,
  FETCH_COMPATS_SEARCH_COMPLETE_MOBILE,
  FETCH_COMPATS_SEARCH_STICKY_START,
  FETCH_COMPATS_SEARCH_STICKY_COMPLETE,
  FETCH_COMPATS_SEARCH_STICKY_COMPLETE_MOBILE,
  FETCH_COMPATS_DELETE_START,
  FETCH_COMPATS_DELETE_COMPLETE,
  FETCH_COMPATS_DELETE_COMPLETE_MOBILE,
  FETCH_COMPATS_SEARCH_ERROR,
  FETCH_COMPATS_DELETE_ERROR,
  FETCH_COMPATS_FEEDBACK_CLOSE,
  FETCH_ON_COUPON_UPDATE_START,
  FETCH_ON_COUPON_UPDATE_COMPLETE,
  FETCH_ON_COUPON_UPDATE_ERROR,
  FETCH_ON_MELIPLUS_UPDATE_START,
  FETCH_ON_MELIPLUS_UPDATE_COMPLETE,
  SHOW_SNACKBAR,
  HIDE_SNACKBAR,
  ON_DEMAND_IFRAME,
  CART_CONGRATS_UPDATE,
  FETCH_ON_DEMAND_IFRAME,
  FETCH_SEQUENCER,
  WISHLIST_SHOW_BOTTOMSHEET_MODAL,
  WISHLIST_CLOSE_BOTTOMSHEET_MODAL,
  PRELOAD_IFRAME,
  UPDATE_GIFT_REGISTRY,
  UPDATE_GIFT_REGISTRY_CHECKBOX,
  TOGGLE_FOLLOW_OPTIMISTICALLY,
  FETCH_ON_SET_FOLLOW_COMPLETE,
  FETCH_QUESTION_AI_START,
  FETCH_QUESTION_AI_COMPLETE,
  FETCH_QUESTION_AI_ERROR,
  INITIAL_QUESION_AI,
  FETCH_ON_CHANGE_INSTALLATION_COMPLETE,
  FETCH_ON_CHANGE_INSTALLATION_START,
  FETCH_ON_CHANGE_INSTALLATION_END,
} = require('./types');

const {
  newPayloadFetchSequencer,
  updateOnePayForAllTrack,
  redirectOrShowSnackbar,
  ACTIONS_SEQUENCER,
  ACTIONS_SEQUENCER_TRACK,
  RENDER_MODES,
} = require('../utils/onePayForAllUtils');
const { getDefaultErrorMessage } = require('../utils/getDefaultErrorMessage');
const { arrayToObjectById } = require('../../reducers/helpers/arrayToObjectById');
const {
  execAddElementToList,
  execRemoveElementFromList,
  execFetchUpdateWishlist,
  execRemoveBookmark,
  execAddBookmark,
} = require('../../utils/giftRegistry');
const {
  VPP_SEQUENCER_DATADOG_KEY_PREFIX,
  VPP_SEQUENCER_DATADOG_KEY_CASES,
  QUESTIONS_AI_DATADOG_KEY_PREFIX,
  QUESTIONS_AI_DATADOG_KEY_CASES,
} = require('../../services/frontend-statsd/config/allowed-keys');
const {
  REASON_TAG_VALUES,
  BUTTON_TYPES_TAG_VALUES,
  QUESTIONS_AI_REASON_TAG_VALUES,
  QUESTIONS_AI_BUTTON_TYPE_TAG_VALUES,
  UNKNOWN_VALUE,
} = require('../../services/frontend-statsd/config/allowed-tags');
const { tracking } = require('../../pages/questions-ai/events/tracking');
const { showInstallationSnackbar } = require('../utils/installationsUtils');

const trackEventCompatsInResponse = (response, isMobile, feature_name = null, isClean = false) => {
  let widgetTrack;
  const getWidgetTrack = (component, id_component = null) => {
    if (component) {
      return id_component
        ? component.find(it => it.id === id_component)?.components?.find(it => it.id === ID_COMPATS_WIDGET).widget_data
            ?.track
        : component.find(item => item.id === ID_COMPATS_WIDGET)?.widget_data?.track;
    }
    return null;
  };

  if (isClean) {
    widgetTrack = isMobile ? getWidgetTrack(response.components) : getWidgetTrack(response.components?.head);
  } else {
    widgetTrack = isMobile
      ? getWidgetTrack(response.components, feature_name ?? 'all_features')
      : getWidgetTrack(response.components?.content_left, feature_name ?? 'technical_specifications');
  }
  widgetTrack =
    widgetTrack ||
    (isMobile
      ? getWidgetTrack(response.components, SELLER)
      : getWidgetTrack(response.components?.short_description, SELLER));
  widgetTrack =
    widgetTrack ||
    (isMobile
      ? getWidgetTrack(response.components, COMPATS)
      : getWidgetTrack(response.components?.short_description, COMPATS));
  if (widgetTrack) {
    trackEvent(widgetTrack);
  }
};

const addElementToList = element => (dispatch, getState) => {
  const {
    components: { wishlist_save_button: wishlist },
  } = getState();

  execAddElementToList(element, dispatch, wishlist, SHOW_SNACKBAR, HIDE_SNACKBAR, FETCH_ERROR);
};

const removeElementFromList = element => dispatch => {
  execRemoveElementFromList(element, dispatch, TOGGLE_BOOKMARKED, SHOW_SNACKBAR, FETCH_ERROR);
};

const getCompatsProductServiceActions = ({
  selectedInput,
  updateState,
  stateComponents,
  feature_name,
  fetchStickyComplete,
  fetchComplete,
  type,
  updateWidgetData,
  isMobile = false,
}) => (dispatch, getState) => {
  const { app, id } = getState();
  const compats_widget_sticky = get(stateComponents, `${feature_name}`);
  const compats_widget_block = get(stateComponents, `${feature_name}.components`);

  const isSticky = type === 'sticky';
  const fetchTypeStart = isSticky ? FETCH_COMPATS_SEARCH_STICKY_START : FETCH_COMPATS_SEARCH_START;
  const fetchTypeComplete = isSticky ? fetchStickyComplete : fetchComplete;
  /* istanbul ignore next */
  const domain_id =
    compats_widget_sticky?.widget_data?.modal?.form?.domain_id ||
    compats_widget_block?.find(item => item.id === ID_COMPATS_WIDGET)?.widget_data?.modal?.form?.domain_id;
  const compats_type =
    compats_widget_sticky?.widget_data?.type ||
    compats_widget_block?.find(item => item.id === ID_COMPATS_WIDGET)?.widget_data?.type;

  const compatsUserSelection = selectedInput.user_selection
    .filter(item => item.selected_value && item.selected_value.id)
    .map(item => `${item.id}:${item.selected_value.id}`)
    .join('|');

  const params = {
    product_id: id,
    app,
    compats_user_selection: compatsUserSelection,
    domain_id,
  };

  dispatch({ type: fetchTypeStart, id, params });
  APIService.getCompatsProduct(params)
    .then(response => {
      dispatch({ type: fetchTypeComplete, payload: response });
      if (!isSticky || compats_type === ID_COMPATS_WIDGET_MESSAGE) {
        updateState(updateWidgetData(response));
      } else {
        trackEventCompatsInResponse(response, isMobile);
      }
      sessionStorage.removeItem(COMPATS_MESSAGE_TOP);
      const event = new StorageEvent('storage', {
        key: COMPATS_MESSAGE_TOP,
        newValue: 'false',
        storageArea: sessionStorage,
      });
      window.dispatchEvent(event);
      window.scroll({ top: 0, behavior: 'smooth' });
    })
    .catch(e => {
      dispatch({ type: FETCH_COMPATS_SEARCH_ERROR, error: e });
    });

  const paramsPost = {
    domain_id,
    app,
  };

  // optimistic POST
  APIService.postCompats(selectedInput, paramsPost)
    .then(() => {})
    .catch(e => {
      dispatch({ type: FETCH_COMPATS_SEARCH_ERROR, error: e });
    });
};

const getCompatsProductDesktop = ({ selectedInput, updateState, feature_name, type }) => (dispatch, getState) => {
  const { components: stateComponents } = getState();
  const compats_widget_block = get(stateComponents, `${feature_name}.components`);

  const fetchStickyComplete = FETCH_COMPATS_SEARCH_STICKY_COMPLETE;
  const fetchComplete = FETCH_COMPATS_SEARCH_COMPLETE;

  const updateWidgetData = response => {
    trackEventCompatsInResponse(response, false, feature_name);
    /* istanbul ignore next */
    if (feature_name === SELLER || feature_name === COMPATS) {
      return {
        ...response.components.short_description
          .find(it => it.id === feature_name)
          .components.find(it => it.id === ID_COMPATS_WIDGET).widget_data,
      };
    }
    if (feature_name === ID_COMPATS_WIDGET) {
      return {
        ...response.components.head.find(item => item.id === ID_COMPATS_WIDGET).widget_data,
      };
    }
    /* istanbul ignore next */
    return {
      ...response.components.content_left
        .find(it => it.id === feature_name)
        .components.find(it => it.id === ID_COMPATS_WIDGET).widget_data,
      modal: {
        ...compats_widget_block.find(it => it.id === ID_COMPATS_WIDGET).widget_data.modal,
      },
    };
  };

  dispatch(
    getCompatsProductServiceActions({
      selectedInput,
      updateState,
      stateComponents,
      feature_name,
      fetchStickyComplete,
      fetchComplete,
      type,
      updateWidgetData,
    }),
  );
};

const getCompatsProductWebmobile = ({ selectedInput, updateState, feature_name, type }) => (dispatch, getState) => {
  const { components: stateComponents } = getState();
  const compats_widget_block = get(stateComponents, `${feature_name}.components`);
  const fetchStickyComplete = FETCH_COMPATS_SEARCH_STICKY_COMPLETE_MOBILE;
  const fetchComplete = FETCH_COMPATS_SEARCH_COMPLETE_MOBILE;

  const updateWidgetData = response => {
    trackEventCompatsInResponse(response, true, feature_name);
    /* istanbul ignore next */
    if (feature_name === SELLER || feature_name === COMPATS) {
      return {
        ...response.components.find(it => it.id === feature_name).components.find(it => it.id === ID_COMPATS_WIDGET)
          .widget_data,
      };
    }
    /* istanbul ignore next */
    return {
      ...response.components.find(it => it.id === feature_name).components.find(it => it.id === ID_COMPATS_WIDGET)
        .widget_data,
      modal: {
        ...compats_widget_block.find(it => it.id === ID_COMPATS_WIDGET).widget_data.modal,
      },
    };
  };

  dispatch(
    getCompatsProductServiceActions({
      selectedInput,
      updateState,
      stateComponents,
      feature_name,
      fetchStickyComplete,
      fetchComplete,
      type,
      updateWidgetData,
      isMobile: true,
    }),
  );
};

const getCompatsProduct = ({ selectedInput, updateState, feature_name, deviceType }, type) => {
  const isMobile = deviceType === 'mobile';
  return isMobile
    ? getCompatsProductWebmobile({ selectedInput, updateState, feature_name, type })
    : getCompatsProductDesktop({ selectedInput, updateState, feature_name, type });
};

const cleanCompatsFeedback = updateState => dispatch => {
  dispatch({ type: FETCH_COMPATS_FEEDBACK_CLOSE, payload: updateState });
};

const cleanFormCompats = ({ feature_name, deviceType, updateState }) => (dispatch, getState) => {
  const { app, id, compats_widget, components: stateComponents } = getState();
  const components = get(stateComponents, `${feature_name}.components`) || get(stateComponents, `${feature_name}`);
  const fetchCompatsDeleteComplete =
    deviceType === 'mobile' ? FETCH_COMPATS_DELETE_COMPLETE_MOBILE : FETCH_COMPATS_DELETE_COMPLETE;
  /* istanbul ignore next */
  const domain_id =
    compats_widget?.widget_data?.modal?.form?.domain_id ||
    components?.widget_data?.modal?.form?.domain_id ||
    components?.find(item => item.id === ID_COMPATS_WIDGET)?.widget_data?.modal?.form?.domain_id;

  const params = {
    product_id: id,
    domain_id,
    app,
  };
  const updateWidgetData = response => {
    /* istanbul ignore next */
    if (feature_name === SELLER || feature_name === COMPATS) {
      return {
        ...response.components.find(it => it.id === feature_name).components.find(it => it.id === ID_COMPATS_WIDGET)
          .widget_data,
      };
    }
    /* istanbul ignore next */
    if (feature_name === ID_COMPATS_WIDGET) {
      return {
        ...response.components.head.find(item => item.id === ID_COMPATS_WIDGET).widget_data,
      };
    }
    return false;
  };

  dispatch({ type: FETCH_COMPATS_DELETE_START, id, params });
  APIService.getCompatsProduct(params)
    .then(response => {
      sessionStorage.removeItem(COMPATS_MESSAGE_TOP);
      dispatch({ type: fetchCompatsDeleteComplete, payload: response });
      const updateWidget = updateWidgetData(response);
      if (updateState && updateWidget) {
        updateState(updateWidget);
      }
      window.scroll({ top: 0, behavior: 'smooth' });
      trackEventCompatsInResponse(response, deviceType === 'mobile', null, true);
    })
    .catch(e => {
      dispatch({ type: FETCH_COMPATS_DELETE_ERROR, error: e });
    });

  const paramsDelete = {
    domain_id,
    app,
  };

  // optimistic DELETE
  APIService.deleteCompats(paramsDelete)
    .then(response => response)
    .catch(e => {
      dispatch({ type: FETCH_ERROR, error: e });
    });
};

const fetchUpdatedWishlists = (forceChecked = null) => (dispatch, getState) => {
  const { id, app } = getState();
  execFetchUpdateWishlist(dispatch, id, app, UPDATE_GIFT_REGISTRY, forceChecked, FETCH_ERROR);
};

const toggleGiftRegistryCheckbox = check => dispatch => {
  dispatch({
    type: UPDATE_GIFT_REGISTRY_CHECKBOX,
    payload: {
      check,
    },
  });
};

const toggleBookmark = () => (dispatch, getState) => {
  const STATUS_OK = 'ok';
  const STATUS_ERROR = 'error';
  const STATUS_SUCCESS = 'success';
  const STATUS_NEUTRAL = 'neutral';
  const CALLED_FROM = 'wishlist_save_button';
  const {
    components: {
      bookmark: { is_bookmarked: isBookmarked, item_id: itemId },
      wishlist_save_button,
    },
    csrfToken,
  } = getState();
  dispatch({ type: TOGGLE_BOOKMARK_OPTIMISTICALLY });

  if (isBookmarked) {
    execRemoveBookmark(dispatch, itemId, STATUS_NEUTRAL, STATUS_OK, STATUS_ERROR, CALLED_FROM);
  } else {
    execAddBookmark(
      dispatch,
      itemId,
      csrfToken,
      wishlist_save_button,
      STATUS_SUCCESS,
      STATUS_OK,
      STATUS_ERROR,
      CALLED_FROM,
    );
  }
};

const fetchBookmark = () => dispatch => {
  dispatch({
    type: TOGGLE_BOOKMARKED,
  });
};

const fetchItemOnShippingUpdate = shippingOptionId => (dispatch, getState) => {
  const {
    id,
    platform,
    app,
    filters: pdp_filters,
    vip_filters,
    components: {
      available_quantity: { picker: { selected: quantity } = { selected: null } },
    },
  } = getState();

  dispatch({
    type: FETCH_ON_SHIPPING_UPDATE_START,
    shippingOptionId,
  });

  APIService.getItem({
    id,
    platform,
    app,
    pdp_filters,
    vip_filters,
    quantity,
    shipping_option_id: shippingOptionId,
  })
    .then(payload => {
      dispatch({ type: FETCH_ON_SHIPPING_UPDATE_COMPLETE, payload, shippingOptionId });
    })
    .catch(e => dispatch({ type: FETCH_ERROR, error: e }));
};

const fetchItemOnMeliplusUpdate = updateAction => (dispatch, getState) => {
  const {
    id,
    platform,
    app,
    filters: pdp_filters,
    vip_filters,
    attributes,
    components: {
      available_quantity: { picker: { selected: quantity } = { selected: null } },
    },
  } = getState();
  dispatch({
    type: FETCH_ON_MELIPLUS_UPDATE_START,
  });

  APIService.getProductUpdateMeliplus(id, {
    id,
    platform,
    attributes,
    app,
    pdp_filters,
    vip_filters,
    quantity,
    update_action: updateAction,
  })
    .then(payload => {
      dispatch({ type: FETCH_ON_MELIPLUS_UPDATE_COMPLETE, payload });
    })
    .catch(e => dispatch({ type: FETCH_ERROR, error: e }));
};

const fetchItemOnSetQuantity = quantity => (dispatch, getState) => {
  const {
    id,
    platform,
    app,
    filters: pdp_filters,
    vip_filters,
    selectedShippingOptionId,
    components,
    hasDeferredErrors,
  } = getState();
  let attributes = get(components, 'variations.selected_attributes', null);
  if (!attributes) {
    attributes = get(components, 'outside_variations.selected_attributes', null);
  }
  if (attributes) {
    attributes = JSON.stringify(attributes).replace(/[{()}]/g, '');
    attributes = attributes.replace(/"/g, '');
  }

  dispatch({
    type: FETCH_ON_SET_QUANTITY_START,
    quantity,
  });

  APIService.getItem({
    id,
    platform,
    app,
    pdp_filters,
    vip_filters,
    quantity,
    shipping_option_id: selectedShippingOptionId,
    attributes,
    has_deferred_errors: hasDeferredErrors,
  })
    .then(payload => {
      dispatch({ type: FETCH_ON_SET_QUANTITY_COMPLETE, payload });
    })
    .catch(e => dispatch({ type: FETCH_ERROR, error: e }));
};

const onQuantityShowInput = show => dispatch => {
  dispatch({
    type: QUANTITY_SHOW_INPUT_CHANGE,
    payload: {
      show,
    },
  });
};

const fetchComponents = (id, params) => (dispatch, getState) => {
  const { app, vip_filters, variation_id } = getState();
  dispatch({ type: FETCH_START, id, params });
  APIService.getItem({ ...params, id, app, vip_filters, trigger_variation_id: variation_id })
    .then(payload => {
      dispatch({ type: FETCH_COMPLETE, payload: { attributes: params.attributes, ...payload } });
    })
    .catch(e => {
      dispatch({ type: FETCH_ERROR, error: e });
    });
};

const fetchUpdatedComponents = (id, params, updateVariationsBox, attrId) => (dispatch, getState) => {
  const { app, vip_filters } = getState();
  dispatch({ type: FETCH_START, id, params });
  APIService.getItemUpdateVariation(id, { ...params, id, app, vip_filters })
    .then(payload => {
      updateVariationsBox(id, attrId, payload);
      dispatch({ type: FETCH_COMPLETE, payload: { attributes: params.attributes, ...payload } });
    })
    .catch(e => {
      dispatch({ type: FETCH_ERROR, error: e });
    });
};

const fetchVariationDetailsByIds = ids => (dispatch, getState) => {
  dispatch({ type: FETCH_START, ids });
  const { id } = getState();
  APIService.getProductVariationsDetails(id, { ids })
    .then(payload => dispatch({ type: FETCH_PRODUCT_VARIATIONS_DETAILS, payload }))
    .catch(e => dispatch({ type: FETCH_ERROR, error: e }));
};

const showVariationsError = (deviceType, actionKey) => dispatch => {
  if (deviceType === 'desktop') {
    dispatch({ type: SHOW_VARIATIONS_ERROR_DESKTOP });
  } else {
    dispatch({ type: SHOW_VARIATIONS_ERROR_MOBILE, payload: { actionKey } });
  }
};

const showQuantityError = () => dispatch => {
  dispatch({ type: SHOW_QUANTITY_ERROR });
};

const fetchMoreQuestionsDesktop = (itemId, offset) => (dispatch, getState) => {
  dispatch({ type: FETCH_MORE_QUESTIONS_START });
  const { app } = getState();

  APIService.getItemQuestions(itemId, { offset, app })
    .then(payload => {
      dispatch({
        type: FETCH_MORE_QUESTIONS_COMPLETE_DESKTOP,
        payload,
      });
    })
    .catch(e => dispatch({ type: FETCH_ERROR, error: e }));
};

const fetchMoreQuestionsMobile = (itemId, offset) => (dispatch, getState) => {
  dispatch({ type: FETCH_MORE_QUESTIONS_START });
  const { app } = getState();

  APIService.getItemQuestions(itemId, { offset, app })
    .then(payload => {
      dispatch({
        type: FETCH_MORE_QUESTIONS_COMPLETE_MOBILE,
        payload,
      });
    })
    .catch(e => dispatch({ type: FETCH_ERROR, error: e }));
};

const onCreateQuestion = ({ itemId, text, track }) => (dispatch, getState) => {
  const { app } = getState();
  dispatch({ type: CREATE_QUESTION_START, itemId });
  APIService.createQuestion(itemId, text, { app })
    .then(payload => {
      if (payload) {
        if (payload.stock_track) {
          trackEvent(payload.stock_track);
        }

        if (!payload.stock_modal) {
          const extraFields = payload.extra_post_event_data || {};
          extraFields.failed = !payload.success;
          trackEventWithCustomFields(track, extraFields);
        }

        if (payload.redirect) {
          window.location = payload.redirect.permalink;
        } else {
          dispatch({ type: CREATE_QUESTION_COMPLETE, payload });
        }
      }
    })
    .catch(e => {
      trackEventWithCustomField('failed', track, true);
      dispatch({ type: CREATE_QUESTION_ERROR, error: e });
    });
};

const onCreateQuestionInView = ({ itemId, text, track, make_only_question }) => (dispatch, getState) => {
  const { app } = getState();
  dispatch({ type: CREATE_QUESTION_START, itemId });
  APIService.createQuestion(itemId, text, { app, make_only_question })
    .then(payload => {
      if (payload.stock_track) {
        trackEvent(payload.stock_track);
      }

      if (!payload.stock_modal) {
        const extraFields = payload.extra_post_event_data || {};
        extraFields.failed = !payload.success;
        trackEventWithCustomFields(track, extraFields);
      }

      if (payload.redirect) {
        window.location = payload.redirect.permalink;
      } else {
        dispatch({ type: CREATE_QUESTION_COMPLETE_VIEW, payload });
      }
    })
    .catch(e => {
      trackEventWithCustomField('failed', track, true);
      dispatch({ type: CREATE_QUESTION_ERROR, error: e });
    });
};

const onCreateQuestionFromAi = ({ text, snackbar_message, suggestionTrack, source }) => (dispatch, getState) => {
  const { app, id: itemId, components } = getState();
  const track = get(components, 'questions.track', null);

  // Datadog config
  const statsdConfig = {
    keyPrefix: QUESTIONS_AI_DATADOG_KEY_PREFIX,
    baseTags: {
      origin: suggestionTrack?.melidata_event?.event_data?.origin || 'form',
      referer_app: app,
      is_post_question_from_webview: false,
      source,
    },
  };

  if (suggestionTrack) {
    const {
      melidata_event: {
        event_data: { origin },
      },
    } = suggestionTrack;

    switch (origin) {
      case 'feedback':
        trackEvent(suggestionTrack);
        break;

      default:
        trackPage(suggestionTrack);
        break;
    }
  }

  dispatch({ type: CREATE_QUESTION_START });
  APIService.createQuestion(itemId, text, { app })
    .then(payload => {
      if (payload) {
        if (payload.stock_track) {
          trackEvent(payload.stock_track);
        }

        if (!payload.stock_modal) {
          const extraFields = payload.extra_post_event_data || {};
          extraFields.failed = !payload.success;
          trackEventWithCustomFields(track, extraFields);
        }

        if (payload.redirect) {
          window.location = payload.redirect.permalink;
        } else {
          feStatsdHelper({
            key: QUESTIONS_AI_DATADOG_KEY_CASES.FLOW_END,
            statsdConfig,
            additionalTags: {
              end_case: QUESTIONS_AI_REASON_TAG_VALUES.ASK_SELLER_SUCCESS,
            },
          });
          dispatch({ type: CREATE_QUESTION_FROM_AI_COMPLETE, payload });
          dispatch({ type: INITIAL_QUESION_AI });
          dispatch({
            type: SHOW_SNACKBAR,
            params: { message: snackbar_message, type: 'success', delay: 3000, called_from: 'questions_ai' },
          });
        }
      }
    })
    .catch(e => {
      trackEventWithCustomField('failed', track, true);
      feStatsdHelper({
        key: QUESTIONS_AI_DATADOG_KEY_CASES.SHOW_SNACKBAR,
        statsdConfig,
        additionalTags: {
          reason: QUESTIONS_AI_REASON_TAG_VALUES.ASK_SELLER_ERROR,
          snackbar_type: 'error',
        },
        shouldLogMessage: e,
        additionalInfo: e,
      });
      dispatch({ type: CREATE_QUESTION_ERROR, error: e });
    });
};

const fetchCompatibleValues = ({ domainId, labelId, values }) => (dispatch, getState) => {
  const { app, components = {} } = getState();
  const { compatibilities } = components;
  dispatch({ type: FETCH_COMPATIBLE_POSIBLE_VALUES_START, domainId, labelId });
  return (
    APIService.getCompatibleValues(domainId, labelId, values, { app })
      // eslint-disable-next-line promise/always-return
      .then(response => {
        const inputs = compatibilities.inputs.map(input => {
          if (input.label.id === labelId) {
            input.values = response.label_values;
          }
          return input;
        });

        dispatch({ type: FETCH_COMPATIBLE_POSIBLE_VALUES_COMPLETE, labelId, inputs, message: response.message });
      })
      .catch(error => {
        dispatch({ type: FETCH_COMPATIBLE_POSIBLE_VALUES_ERROR, error });
      })
  );
};

const setSelectedValue = ({ labelId, value }) => dispatch => {
  dispatch({ type: SET_COMPATIBILITIES_INPUT_VALUE, labelId, value });
};

const fetchCompatibilities = ({ domainId, values }) => (dispatch, getState) => {
  const { app, id: itemId } = getState();
  dispatch({ type: FETCH_COMPATIBILITIES_WITH_USER_SELECTION_START, domainId });
  return (
    APIService.searchCompatibilities(itemId, domainId, values, { app })
      // eslint-disable-next-line promise/always-return
      .then(payload => {
        dispatch({ type: FETCH_COMPATIBILITIES_WITH_USER_SELECTION_COMPLETE, payload });
      })
      .catch(error => {
        dispatch({ type: FETCH_COMPATIBILITIES_WITH_USER_SELECTION_ERROR, error });
      })
  );
};

const fetchShippingCalculatorOptions = () => (dispatch, getState) => {
  const { id, destinationValue, components } = getState();

  let attributes = get(components, 'variations.selected_attributes', null);
  if (!attributes) {
    attributes = get(components, 'outside_variations.selected_attributes', null);
  }

  if (attributes) {
    attributes = JSON.stringify(attributes).replace(/[{()}]/g, '');
    attributes = attributes.replace(/"/g, '');
  }

  dispatch({
    type: FETCH_ON_SHIPPING_UPDATE_START,
  });
  APIService.getShippingCalculatorOptions({
    itemId: id,
    app: 'vpp',
    destination_value: destinationValue,
    attributes,
  })
    .then(payload => {
      dispatch({ type: FETCH_ON_SHIPPING_UPDATE_COMPLETE, payload, destinationValue });
    })
    .catch(e => dispatch({ type: FETCH_ERROR, error: e }));
};

const addToCartUpdate = ({ action, quantity, target, onSuccess, onError }) => (dispatch, getState) => {
  const { app } = getState();

  const data = {
    action,
    quantity,
    ...target,
  };

  const params = { app };

  const request = () => {
    dispatch({ type: ADD_TO_CART_UPDATE_START });
    requestQueue.isRequestInProgress = true;

    APIService.addToCartUpdate(data, params)
      .then(payload => {
        const callback = () => {
          dispatch({ type: ADD_TO_CART_UPDATE_COMPLETE, payload });
          if (payload.feedback && payload.feedback.style === FEEDBACK_STYLE_ERROR) {
            onError();
          } else {
            onSuccess();
          }
        };
        processRequest(callback);
      })
      .catch(e => {
        const callback = () => {
          dispatch({ type: ADD_TO_CART_UPDATE_ERROR, error: e });
          if (onError) {
            onError();
          }
        };
        processRequest(callback);
      });
  };

  if (requestQueue.isRequestInProgress) {
    requestQueue.pendingRequest = request;
  } else {
    request();
  }
};

const fetchUpdatedCoupon = () => (dispatch, getState) => {
  const { id, app, selected_offer_type: offer_type } = getState();

  dispatch({ type: FETCH_ON_COUPON_UPDATE_START, id });
  APIService.getProductUpdateCoupon(id, { app, offer_type })
    .then(payload => {
      dispatch({ type: FETCH_ON_COUPON_UPDATE_COMPLETE, payload });
    })
    .catch(e => {
      dispatch({ type: FETCH_ERROR, error: e });
    });
};

const postCouponActivate = ({ track, ...params }) => (dispatch, getState) => {
  const {
    app,
    components: {
      available_quantity: {
        picker: { selected: quantity },
      },
      main_actions: {
        form: { item_id },
      },
    },
  } = getState();

  if (track) {
    trackEvent(track);
  }

  dispatch({ type: FETCH_ON_COUPON_UPDATE_START, id: item_id });
  APIService.postProductCouponActivate(item_id, { app, quantity, ...params })
    .then(payload => {
      if (payload?.components?.coupon_summary?.awareness?.track) {
        trackEvent(payload.components.coupon_summary.awareness.track);
      }
      dispatch({ type: FETCH_ON_COUPON_UPDATE_COMPLETE, payload });
    })
    .catch(e => {
      const { siteId } = getState();
      dispatch({ type: FETCH_ON_COUPON_UPDATE_ERROR });
      dispatch({
        type: SHOW_SNACKBAR,
        params: {
          message: e?.response?.data?.display_message ?? getDefaultErrorMessage(siteId),
          type: 'error',
          delay: 6000,
          called_from: 'coupon_summary',
        },
      });
    });
};

const showSnackbar = ({ message, type, delay, called_from, action, className }) => (dispatch, getState) => {
  const { siteId } = getState();
  const DEFAULT_ERROR_MESSAGE = getDefaultErrorMessage(siteId);
  dispatch({
    type: SHOW_SNACKBAR,
    params: {
      message: message || DEFAULT_ERROR_MESSAGE,
      type,
      delay: delay || 3000,
      called_from: called_from || 'vip',
      action,
      className,
    },
  });
};

const hideSnackbar = () => dispatch => {
  dispatch({ type: HIDE_SNACKBAR });
};

const showAddToCartModal = ({ itemId, labelText }) => (dispatch, getState) => {
  const {
    components,
    components: {
      available_quantity: { picker: { selected: quantity } = { selected: null } },
      main_actions: { form },
    },
    shopsDefaultTrackingData,
  } = getState();
  const data = {
    quantity,
    ...form,
    item_id: itemId,
  };
  dispatch({ type: FETCH_ON_DEMAND_IFRAME, params: { loading: true, labelText, isFetching: false } });
  const urlAddToCartServiceResponse = APIService.addToCartModal(data, itemId)
    .then(response => {
      dispatch({ type: FETCH_ON_DEMAND_IFRAME, params: { loading: false, labelText, isFetching: false } });
      if (response?.data?.shouldOpenModal) {
        dispatch({
          type: ON_DEMAND_IFRAME,
          params: {
            show: response.data.shouldOpenModal,
            src: response.data.target,
            isRedirectFlow: false,
            renderMode: RENDER_MODES.CONTAINER,
            isDismissible: response.data.is_dismissible,
          },
        });
      } else if (response?.data?.target) {
        window.location.href = response.data.target;
      } else if (response?.data?.error_message) {
        dispatch({ type: FETCH_ERROR, error: response.data.error_message });
      }
      const quantitySelected = components.available_quantity.picker.selected;
      return { quantitySelected, shopsDefaultTrackingData, urlAddToCartServiceResponse: response?.data?.target };
    })
    .catch(e => {
      dispatch({ type: FETCH_ERROR, error: e });
      dispatch({ type: FETCH_ON_DEMAND_IFRAME, params: { loading: false, labelText, isFetching: false } });
    });
  return urlAddToCartServiceResponse;
};

const updateComponentsBottomSheet = () => (dispatch, getState) => {
  const {
    id,
    filters: pdp_filters,
    vip_filters,
    components,
    components: {
      available_quantity: { picker: { selected: quantity } = { selected: null } },
    },
    app,
    platform,
    selectedShippingOptionId,
    hasDeferredErrors,
  } = getState();
  let attributes = get(components, 'variations.selected_attributes', null);
  if (!attributes) {
    attributes = get(components, 'outside_variations.selected_attributes', null);
  }
  if (attributes) {
    attributes = JSON.stringify(attributes).replace(/[{()}]/g, '');
    attributes = attributes.replace(/"/g, '');
  }
  APIService.getItem({
    id,
    platform,
    app,
    pdp_filters,
    vip_filters,
    quantity,
    shipping_option_id: selectedShippingOptionId,
    attributes,
    has_deferred_errors: hasDeferredErrors,
    transform_path: true,
    a2c_update_freeshiping_bar: true,
  })
    .then(payload => {
      dispatch({ type: CART_CONGRATS_UPDATE, payload });
    })
    .catch(e => dispatch({ type: FETCH_ERROR, error: e }));
};

const showQuestionsAiModal = ({ target, isDismissible, disabled, called_from, source }) => (dispatch, getState) => {
  const { app } = getState();

  // Datadog config
  const statsdConfig = {
    keyPrefix: QUESTIONS_AI_DATADOG_KEY_PREFIX,
    baseTags: {
      button_id: `${called_from}.${BUTTON_TYPES_TAG_VALUES.ASK_QUESTIONS_AI}`,
      referer_app: app,
      is_disabled: Boolean(disabled),
      called_from,
      source,
    },
  };

  // DATADOG - ACTION CLICK
  feStatsdHelper({
    key: QUESTIONS_AI_DATADOG_KEY_CASES.ACTION_CLICK,
    statsdConfig,
    additionalTags: {
      has_target: Boolean(target),
    },
  });

  if (target) {
    dispatch({
      type: ON_DEMAND_IFRAME,
      params: {
        show: true,
        src: target,
        isRedirectFlow: false,
        renderMode: RENDER_MODES.CONTAINER,
        isDismissible,
        statsdConfig,
        fallbackConfig: {},
        customNamespace: 'questions-ai',
        noCloseButton: true,
      },
    });
  } else {
    feStatsdHelper({
      key: QUESTIONS_AI_DATADOG_KEY_CASES.SHOW_SNACKBAR,
      statsdConfig,
      additionalTags: {
        reason: QUESTIONS_AI_REASON_TAG_VALUES.EMPTY_TARGET,
        snackbar_type: 'error',
      },
    });

    dispatch({
      type: SHOW_SNACKBAR,
      params: { type: 'error', delay: 3000, called_from: 'vip' },
    });
  }
};

const showPaymentsSplitModal = ({ target }) => dispatch => {
  dispatch({
    type: ON_DEMAND_IFRAME,
    params: {
      show: true,
      src: target,
      isRedirectFlow: false,
      renderMode: RENDER_MODES.CONTAINER,
      isDismissible: true,
      fallbackConfig: {},
      customNamespace: 'split-payments',
    },
  });
};

const onDemandIframeClose = () => dispatch => {
  dispatch({
    type: ON_DEMAND_IFRAME,
    params: {
      show: false,
      src: null,
      isRedirectFlow: false,
      fallbackConfig: null,
      statsdConfig: null,
      sequencerTrack: null,
      customNamespace: null,
      noCloseButton: false,
    },
    isFetching: false,
  });
};

const onDemandIframeUpdate = params => dispatch => {
  dispatch({
    type: ON_DEMAND_IFRAME,
    params,
  });
};

const triggerBuyNowOnePayForAll = actionKey => (dispatch, getState) => {
  const {
    components: {
      available_quantity: { picker: { selected: quantity } = { selected: null } },
      main_actions: { form, actions },
    },
    siteId,
  } = getState();

  dispatch({
    type: FETCH_SEQUENCER,
    payload: newPayloadFetchSequencer(actions, true, actionKey),
  });

  APIService.buyNowOnePayForAll({ ...form, quantity })
    .then(response => {
      window.location.href = response.target;
    })
    .catch(e => {
      dispatch({
        type: FETCH_SEQUENCER,
        payload: newPayloadFetchSequencer(actions, false, actionKey),
      });

      dispatch({
        type: SHOW_SNACKBAR,
        params: {
          message: e.response.data.displayMessage ?? getDefaultErrorMessage(siteId),
          type: 'error',
          delay: 3000,
          called_from: 'vip',
        },
      });
    });
};

const openWishlistModalBS = () => dispatch => {
  dispatch({ type: HIDE_SNACKBAR });
  dispatch({ type: WISHLIST_SHOW_BOTTOMSHEET_MODAL });
};

const closeWishlistModalBS = isBookmarked => dispatch => {
  dispatch({ type: WISHLIST_CLOSE_BOTTOMSHEET_MODAL, payload: { isBookmarked } });
};

const redirectToLogin = ({ itemId, loginType, featureName }) => () => {
  try {
    APIService.redirectToLogin(itemId, loginType, featureName);
  } catch (error) {
    throw new Error(error);
  }
};

/* eslint-disable complexity */
const triggerSequencer = actionKey => (dispatch, getState) => {
  const {
    app,
    components: {
      main_actions: { actions },
    },
    preload,
    siteId,
  } = getState();

  const action = actions.find(act => actionKey === act.label.text);

  const {
    fallback,
    target,
    disabled,
    display_instantly,
    trigger_action: triggerAction,
    intervention_id: interventionId,
    track,
  } = action;

  if (!display_instantly) {
    dispatch({
      type: FETCH_SEQUENCER,
      payload: newPayloadFetchSequencer(actions, true, actionKey),
    });
  }

  let fallbackUrl = fallback;

  // Datadog config
  const statsdConfig = {
    keyPrefix: VPP_SEQUENCER_DATADOG_KEY_PREFIX,
    baseTags: {
      button_type: triggerAction || UNKNOWN_VALUE,
      referer_app: app,
      is_disabled: Boolean(disabled),
      intervention_id: interventionId || UNKNOWN_VALUE,
    },
  };

  // DATADOG - action click
  feStatsdHelper({
    key: VPP_SEQUENCER_DATADOG_KEY_CASES.ACTION_CLICK,
    additionalTags: {
      main_action_target: Boolean(target),
      main_action_fallback: Boolean(fallback),
      main_action_empty: !target && !fallback,
    },
    additionalInfo: { action },
    shouldLogMessage: !target || !fallback,
    statsdConfig,
  });

  if (!target) {
    dispatch({
      type: FETCH_SEQUENCER,
      payload: newPayloadFetchSequencer(actions, false, actionKey),
    });
    redirectOrShowSnackbar({
      dispatch,
      siteId,
      fallbackToRedirect: null,
      reason: REASON_TAG_VALUES.NO_MAIN_ACTION_TARGET,
      statsdConfig,
      trackData: track,
    });
    return;
  }

  if (display_instantly) {
    if (preload?.isIframeLoaded) {
      feStatsdHelper({
        key: VPP_SEQUENCER_DATADOG_KEY_CASES.DISPLAY_INSTANTLY,
        additionalTags: {
          preload_ready: true,
        },
        statsdConfig,
      });
      dispatch({
        type: PRELOAD_IFRAME,
        params: {
          show: true,
          fallback: fallbackUrl,
          statsdConfig,
        },
      });
    } else {
      feStatsdHelper({
        key: VPP_SEQUENCER_DATADOG_KEY_CASES.DISPLAY_INSTANTLY,
        additionalTags: {
          preload_ready: false,
        },
        statsdConfig,
      });
      dispatch({
        type: FETCH_SEQUENCER,
        payload: newPayloadFetchSequencer(actions, true, actionKey),
      });
    }
  }

  const params = Object.fromEntries(new URLSearchParams(target.split('?')[1]));

  params.is_preload_shown = Boolean(preload?.isIframeLoaded);

  APIService.getSequencerOnePayForAll(params)
    .then(response => {
      const knownCases = [
        ACTIONS_SEQUENCER.BOTTOM_SHEET_WITH_PRELOAD,
        ACTIONS_SEQUENCER.BOTTOM_SHEET,
        ACTIONS_SEQUENCER.LANDING_WITH_PRELOAD,
        ACTIONS_SEQUENCER.LANDING,
        ACTIONS_SEQUENCER.SNACKBAR,
        ACTIONS_SEQUENCER.NAVIGATE,
      ];
      const actionType = response?.actions?.action_type ?? '';
      const targetUrl = response?.actions?.target;
      const responseFallback = response?.actions?.fallback;
      if (responseFallback) {
        fallbackUrl = responseFallback;
      }
      const fallbackConfig = {
        fallbackUrl,
        fallbackMessage: response?.actions?.fallback_message,
        timeoutInitialLoad: response?.actions?.timeout,
        timeoutWebviewMessage: response?.actions?.timeout_webview_message,
      };

      const isDismissible = response?.actions?.bottom_sheet?.is_dismissible;
      const snackbarMessage = response?.actions?.snackbar?.message;
      const snackbarType = response?.actions?.snackbar?.type;
      const sequencerTrack = response?.actions?.track;
      const infoUpdate = response?.actions?.info_update || false;

      dispatch({
        type: FETCH_SEQUENCER,
        payload: newPayloadFetchSequencer(actions, false, actionKey),
      });

      if (sequencerTrack) {
        trackEvent(updateOnePayForAllTrack(sequencerTrack, ACTIONS_SEQUENCER_TRACK.RESPONSE));
      }

      // DATADOG - get response
      feStatsdHelper({
        key: VPP_SEQUENCER_DATADOG_KEY_CASES.GET_RESPONSE,
        additionalTags: {
          response_target: Boolean(targetUrl),
          response_fallback: Boolean(responseFallback),
          response_empty: !targetUrl && !responseFallback,
          response_case: actionType,
        },
        additionalInfo: { response, params },
        shouldLogMessage: !targetUrl || !responseFallback || !knownCases.includes(actionType),
        statsdConfig,
      });

      switch (actionType) {
        // NOTA: bottom_sheet_with_preload o landing_with_preload "hacen lo mismo"
        // El modo de visualización es responsabilidad / tarea de la webview
        // Pues nosotros mostramos un iframe del 100% y la misma webview decide que dibujar dentro
        case ACTIONS_SEQUENCER.BOTTOM_SHEET_WITH_PRELOAD:
        case ACTIONS_SEQUENCER.LANDING_WITH_PRELOAD:
          if (!preload?.isIframeLoaded) {
            redirectOrShowSnackbar({
              dispatch,
              siteId,
              fallbackToRedirect: fallbackUrl,
              reason: REASON_TAG_VALUES.NO_WEBVIEW_PRELOADED,
              message: fallbackConfig.fallbackMessage,
              statsdConfig,
              trackData: sequencerTrack,
            });
            break;
          }
          feStatsdHelper({
            key: VPP_SEQUENCER_DATADOG_KEY_CASES.FLOW_END,
            statsdConfig,
            additionalTags: {
              end_case: actionType,
            },
          });
          dispatch({
            type: PRELOAD_IFRAME,
            params: {
              show: true,
              fallback: fallbackUrl,
              infoUpdate,
              statsdConfig,
            },
          });
          break;
        case ACTIONS_SEQUENCER.BOTTOM_SHEET:
        case ACTIONS_SEQUENCER.LANDING:
          if (!targetUrl) {
            redirectOrShowSnackbar({
              dispatch,
              siteId,
              fallbackToRedirect: fallbackUrl,
              reason:
                actionType === ACTIONS_SEQUENCER.BOTTOM_SHEET
                  ? REASON_TAG_VALUES.NO_BOTTOMSHEET_TARGET
                  : REASON_TAG_VALUES.NO_LANDING_TARGET,
              message: fallbackConfig.fallbackMessage,
              statsdConfig,
              trackData: sequencerTrack,
            });
            break;
          }
          if (sequencerTrack) {
            trackEvent(updateOnePayForAllTrack(sequencerTrack, ACTIONS_SEQUENCER_TRACK.LOADING));
          }

          dispatch({
            type: ON_DEMAND_IFRAME,
            params: {
              show: true,
              src: targetUrl,
              isRedirectFlow: false,
              renderMode:
                actionType === ACTIONS_SEQUENCER.BOTTOM_SHEET ? RENDER_MODES.CONTAINER : RENDER_MODES.FULLSCREEN,
              fallbackConfig,
              statsdConfig,
              isDismissible,
              sequencerTrack,
            },
          });
          break;
        case ACTIONS_SEQUENCER.SNACKBAR:
          dispatch({
            type: PRELOAD_IFRAME,
            params: {
              show: false,
            },
          });
          feStatsdHelper({
            key: VPP_SEQUENCER_DATADOG_KEY_CASES.FLOW_END,
            statsdConfig,
            additionalTags: {
              end_case: actionType,
              snackbar_type: snackbarType || 'error',
            },
          });
          dispatch({
            type: SHOW_SNACKBAR,
            params: {
              message: snackbarMessage ?? getDefaultErrorMessage(siteId),
              type: snackbarType ? snackbarType.toLowerCase() : 'error',
              delay: 3000,
              called_from: 'vip',
            },
          });
          break;
        case ACTIONS_SEQUENCER.NAVIGATE:
          dispatch({
            type: PRELOAD_IFRAME,
            params: {
              show: false,
            },
          });
          if (!targetUrl) {
            redirectOrShowSnackbar({
              dispatch,
              siteId,
              fallbackToRedirect: fallbackUrl,
              reason: REASON_TAG_VALUES.NO_NAVIGATE_TARGET,
              message: fallbackConfig.fallbackMessage,
              statsdConfig,
              trackData: sequencerTrack,
            });
            break;
          }
          feStatsdHelper({
            key: VPP_SEQUENCER_DATADOG_KEY_CASES.FLOW_END,
            statsdConfig,
            additionalTags: {
              end_case: actionType,
            },
          });
          window.location.href = targetUrl;
          break;
        default:
          dispatch({
            type: PRELOAD_IFRAME,
            params: {
              show: false,
            },
          });
          redirectOrShowSnackbar({
            dispatch,
            siteId,
            fallbackToRedirect: null,
            reason: REASON_TAG_VALUES.UNKNOWN_CASE,
            message: fallbackConfig.fallbackMessage,
            statsdConfig,
            trackData: sequencerTrack,
          });
          break;
      }
    })
    .catch(error => {
      dispatch({
        type: PRELOAD_IFRAME,
        params: {
          show: false,
        },
      });
      dispatch({
        type: FETCH_SEQUENCER,
        payload: newPayloadFetchSequencer(actions, false, actionKey),
      });
      redirectOrShowSnackbar({
        dispatch,
        siteId,
        fallbackToRedirect: fallbackUrl,
        reason: REASON_TAG_VALUES.CATCH_SEQUENCER,
        additionalInfo: { error, params },
        shouldLogMessage: true,
        statsdConfig,
        trackData: track,
      });
    });
};
/* eslint-enable complexity */

const preloadIframeLoaded = () => dispatch => {
  dispatch({ type: PRELOAD_IFRAME, params: { isIframeLoaded: true } });
};

const preloadIframeClose = () => dispatch => {
  dispatch({ type: PRELOAD_IFRAME, params: { show: false } });
};

const preloadIframeUpdate = params => dispatch => {
  dispatch({ type: PRELOAD_IFRAME, params });
};

const toggleFollowSeller = () => (dispatch, getState) => {
  const {
    app,
    components: { seller_data: sellerData },
    siteId,
  } = getState();
  const { followers } = sellerData.components.find(el => el.id === 'seller_header');
  const params = new URLSearchParams(followers.query_params);
  params.append('app', app);
  params.append('site_id', siteId);

  dispatch({ type: TOGGLE_FOLLOW_OPTIMISTICALLY });
  if (followers.cta_status === 'do_follow') {
    trackEvent(followers.cta_do_follow.track_event);
    APIService.followSeller(followers.seller_id, params)
      .then(payload => {
        if (payload.status === 'ERROR') {
          dispatch({
            type: SHOW_SNACKBAR,
            params: {
              message: payload.snackbar?.message,
              type: payload.snackbar?.color,
              delay: payload.snackbar?.delay || 3000,
              called_from: 'follow_button',
            },
          });
        }
        dispatch({ type: FETCH_ON_SET_FOLLOW_COMPLETE, payload });
      })
      .catch(e => dispatch({ type: SHOW_SNACKBAR, error: e }));
  } else {
    trackEvent(followers.cta_stop_follow.track_event);
    APIService.unfollowSeller(followers.seller_id, params)
      .then(payload => {
        if (payload.status === 'ERROR') {
          dispatch({
            type: SHOW_SNACKBAR,
            params: {
              message: payload.snackbar?.message,
              type: payload.snackbar?.color,
              delay: payload.snackbar?.delay || 3000,
              called_from: 'follow_button',
            },
          });
        }
        dispatch({ type: FETCH_ON_SET_FOLLOW_COMPLETE, payload });
      })
      .catch(e => dispatch({ type: SHOW_SNACKBAR, error: e }));
  }
};

const saveFrontendStatsd = data => (dispatch, getState) => {
  const { app: referer_app } = getState();

  if (!data) {
    return;
  }

  data.tags.referer_app = referer_app;

  APIService.saveFrontendStatsd(data);
};

const postQuestionFromAi = ({ productId, itemId, text, track, attributes, quantity, source }) => (
  dispatch,
  getState,
) => {
  const { app } = getState();

  // Datadog config
  const statsdConfig = {
    keyPrefix: QUESTIONS_AI_DATADOG_KEY_PREFIX,
    baseTags: {
      referer_app: app,
      source,
    },
  };

  // DATADOG - ACTION CLICK
  feStatsdHelper({
    key: QUESTIONS_AI_DATADOG_KEY_CASES.ACTION_CLICK,
    statsdConfig,
    additionalTags: {
      button_type: QUESTIONS_AI_BUTTON_TYPE_TAG_VALUES.SEND_QUESTION,
    },
  });

  dispatch({ type: FETCH_QUESTION_AI_START });

  if (track) {
    tracking({ track });
  }

  APIService.postQuestion(itemId, { productId, text, app, attributes, quantity })
    .then(payload => {
      if (payload) {
        if (payload.track) {
          tracking({ track: payload.track });
        }

        feStatsdHelper({
          key: QUESTIONS_AI_DATADOG_KEY_CASES.SUGGESTION_RESPONSE,
          statsdConfig,
          additionalTags: {
            classification: payload.track?.melidata_event?.event_data?.classification,
          },
        });

        if (payload.make_action) {
          dispatch(
            onCreateQuestionFromAi({
              itemId,
              text: payload.make_action.config.user_question,
              snackbar_message: payload.make_action.snackbar_message,
              source,
            }),
          );
        } else {
          dispatch({ type: FETCH_QUESTION_AI_COMPLETE, payload: arrayToObjectById(payload.components) });
        }
      }
    })
    .catch(e => {
      feStatsdHelper({
        key: QUESTIONS_AI_DATADOG_KEY_CASES.SHOW_SNACKBAR,
        statsdConfig,
        additionalTags: {
          reason: QUESTIONS_AI_REASON_TAG_VALUES.SUGGESTION_RESPONSE_NOT_LOADED,
          snackbar_type: 'error',
        },
        shouldLogMessage: e,
        additionalInfo: e,
      });
      dispatch({ type: FETCH_QUESTION_AI_ERROR, error: e });
    });
};

const isUseful = ({ itemId, useful, id, response, track, source }) => (dispatch, getState) => {
  const { app } = getState();

  // Datadog config
  const statsdConfig = {
    keyPrefix: QUESTIONS_AI_DATADOG_KEY_PREFIX,
    baseTags: {
      button_type: QUESTIONS_AI_BUTTON_TYPE_TAG_VALUES.FEEDBACK,
      source,
    },
  };

  // DATADOG - ACTION CLICK
  feStatsdHelper({
    key: QUESTIONS_AI_DATADOG_KEY_CASES.ACTION_CLICK,
    statsdConfig,
    additionalTags: {
      like: Boolean(useful),
    },
  });

  if (track) {
    tracking({ track });
  }

  APIService.likeDislikeQuestionsAi(itemId, { useful, id, response, app })
    .then(() => {})
    .catch(e => {
      feStatsdHelper({
        key: QUESTIONS_AI_DATADOG_KEY_CASES.SHOW_SNACKBAR,
        statsdConfig,
        additionalTags: {
          reason: QUESTIONS_AI_REASON_TAG_VALUES.FEEDBACK_ERROR,
          snackbar_type: 'error',
        },
        shouldLogMessage: e,
        additionalInfo: e,
      });
      dispatch({ type: FETCH_ERROR, error: e });
    });
};

const updateInstallation = agency => (dispatch, getState) => {
  const {
    filters: pdp_filters,
    vip_filters,
    app,
    id,
    components: {
      available_quantity: {
        picker: { selected: quantity },
      },
    },
  } = getState();
  const params = {
    product_id: id,
    app,
    ...agency,
    quantity,
    operation: OPERATION_FILL,
    pdp_filters,
    vip_filters,
  };
  dispatch({ type: FETCH_ON_CHANGE_INSTALLATION_START, id });
  APIService.getInstallationsProduct(params)
    .then(payload => {
      dispatch({ type: FETCH_ON_CHANGE_INSTALLATION_COMPLETE, payload: { id, ...payload } });
      showInstallationSnackbar(payload, dispatch);
    })
    .catch(e => {
      dispatch({ type: FETCH_ERROR, error: e });
      dispatch({ type: FETCH_ON_CHANGE_INSTALLATION_END });
    });
};
const removeInstallation = () => (dispatch, getState) => {
  const {
    filters: pdp_filters,
    vip_filters,
    app,
    id,
    components: {
      available_quantity: {
        picker: { selected: quantity },
      },
    },
  } = getState();
  const params = {
    product_id: id,
    app,
    operation: OPERATION_REMOVE,
    quantity,
    pdp_filters,
    vip_filters,
  };
  dispatch({ type: FETCH_ON_CHANGE_INSTALLATION_START, id });
  APIService.getInstallationsProduct(params)
    .then(payload => {
      dispatch({ type: FETCH_ON_CHANGE_INSTALLATION_COMPLETE, payload: { id, ...payload } });
      showInstallationSnackbar(payload, dispatch);
    })
    .catch(e => {
      dispatch({ type: FETCH_ERROR, error: e });
      dispatch({ type: FETCH_ON_CHANGE_INSTALLATION_END });
    });
};

module.exports = {
  toggleBookmark,
  fetchBookmark,
  fetchComponents,
  fetchUpdatedComponents,
  fetchVariationDetailsByIds,
  fetchItemOnSetQuantity,
  fetchItemOnShippingUpdate,
  fetchItemOnMeliplusUpdate,
  onQuantityShowInput,
  fetchUpdatedCoupon,
  postCouponActivate,
  showVariationsError,
  showQuantityError,
  fetchMoreQuestionsDesktop,
  fetchMoreQuestionsMobile,
  onCreateQuestion,
  onCreateQuestionInView,
  fetchCompatibleValues,
  setSelectedValue,
  fetchCompatibilities,
  fetchShippingCalculatorOptions,
  addToCartUpdate,
  getCompatsProduct,
  cleanFormCompats,
  cleanCompatsFeedback,
  showSnackbar,
  hideSnackbar,
  showAddToCartModal,
  triggerBuyNowOnePayForAll,
  showPaymentsSplitModal,
  showQuestionsAiModal,
  onCreateQuestionFromAi,
  triggerSequencer,
  onDemandIframeClose,
  onDemandIframeUpdate,
  preloadIframeLoaded,
  preloadIframeClose,
  preloadIframeUpdate,
  openWishlistModalBS,
  closeWishlistModalBS,
  toggleGiftRegistryCheckbox,
  redirectToLogin,
  updateComponentsBottomSheet,
  addElementToList,
  removeElementFromList,
  fetchUpdatedWishlists,
  toggleFollowSeller,
  saveFrontendStatsd,
  postQuestionFromAi,
  isUseful,
  updateInstallation,
  removeInstallation,
};
